import styled from 'styled-components'
import { ToggleProps, HandleProps, InputProps, ScaleKeys, scales } from './types'

const scaleKeyValues = {
  sm: {
    handleHeight: '18px',
    handleWidth: '24px',
    handleLeft: '1px',
    handleTop: '2px',
    checkedLeft: 'calc(100% - 25px)',
    toggleHeight: '24px',
    toggleWidth: '46px',
  },
  md: {
    handleHeight: '26px',
    handleWidth: '36px',
    handleLeft: '3px',
    handleTop: '2px',
    checkedLeft: 'calc(100% - 38px)',
    toggleHeight: '32px',
    toggleWidth: '75px',
  },
  lg: {
    handleHeight: '32px',
    handleWidth: '32px',
    handleLeft: '4px',
    handleTop: '4px',
    checkedLeft: 'calc(100% - 36px)',
    toggleHeight: '40px',
    toggleWidth: '72px',
  },
}

const getScale =
  (property: ScaleKeys) =>
  ({ scale = scales.LG }: ToggleProps) => {
    return scaleKeyValues[scale][property]
  }

export const Handle = styled.div<HandleProps>`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: inherit;
  border: 1px solid black;
  cursor: pointer;
  height: ${getScale('handleHeight')};
  left: ${getScale('handleLeft')};
  position: absolute;
  top: ${getScale('handleTop')};
  transition: left 200ms ease-in;
  width: ${getScale('handleWidth')};
  z-index: 1;
`

export const Input = styled.input<InputProps>`
  cursor: pointer;
  opacity: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 3;

  &:checked + ${Handle} {
    left: ${getScale('checkedLeft')};
  }

  &:focus + ${Handle} {
  }

  &:hover + ${Handle}:not(:disabled):not(:checked) {
  }
`

const StyledToggle = styled.div<ToggleProps>`
  align-items: center;
  background-color: ${({ theme, checked }) => theme.colors[checked ? 'pinkDis' : 'pinkWhite']};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  cursor: pointer;
  display: inline-flex;
  height: ${getScale('toggleHeight')};
  position: relative;
  transition: background-color 200ms;
  width: ${getScale('toggleWidth')};
  border: 1px solid ${({ theme }) => theme.colors.primary};
`

export default StyledToggle
