import styled from 'styled-components'
import { Card } from 'packages/uikit'

export const BodyWrapper = styled(Card)`
  border-radius: 8px;
  max-width: 774px;
  width: 100%;
  z-index: 1;
  background: #f2fcfc;
  border: 2px solid black;
  border-radius: 30px;

  ${({ theme }) => theme.mediaQueries.xxl} {
    min-width: 665px;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
