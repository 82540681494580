import React from 'react'
import { useTheme } from 'styled-components'
import Heading from '../../components/Heading/Heading'
import getThemeValue from '../../util/getThemeValue'
import {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalContainer,
  ModalCloseButton,
  ModalBackButton,
  HiddenPosition,
} from './styles'
import { ModalProps } from './types'

const Modal: React.FC<ModalProps> = ({
  title,
  onDismiss,
  onBack,
  children,
  hideCloseButton = false,
  bodyPadding = '24px',
  headerBackground = 'transparent',
  minWidth = '265px',
  maxWidth = '448px',
  ...props
}) => {
  const theme = useTheme()
  return (
    <ModalContainer minWidth={minWidth} maxWidth={maxWidth} {...props}>
      <ModalHeader background={getThemeValue(`colors.${headerBackground}`, headerBackground)(theme)}>
        {onBack ? (
          <ModalBackButton onBack={onBack} />
        ) : (
          <HiddenPosition>
            <ModalCloseButton onDismiss={onDismiss} />
          </HiddenPosition>
        )}

        <ModalTitle>
          <Heading color="black">{title}</Heading>
        </ModalTitle>
        {!hideCloseButton && <ModalCloseButton onDismiss={onDismiss} />}
      </ModalHeader>
      <ModalBody p={bodyPadding}>{children}</ModalBody>
    </ModalContainer>
  )
}

export default Modal
