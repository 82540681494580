import { useState } from 'react'
import { escapeRegExp } from 'utils'
import { Text, Button, Input, Flex, Box } from 'packages/uikit'
import { useTranslation } from 'contexts/Localization'
import { useUserSlippageTolerance, useUserTransactionTTL } from 'state/user/hooks'
import { isMobile } from 'react-device-detect'
import { FlexGap } from 'components/Layout/Flex'
import QuestionHelper from '../../QuestionHelper'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh',
}

enum DeadlineError {
  InvalidInput = 'InvalidInput',
}

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

const SlippageTabs = () => {
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance()
  const [ttl, setTtl] = useUserTransactionTTL()
  const [slippageInput, setSlippageInput] = useState('')
  const [deadlineInput, setDeadlineInput] = useState('')

  const { t } = useTranslation()

  const slippageInputIsValid =
    slippageInput === '' || (userSlippageTolerance / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)
  const deadlineInputIsValid = deadlineInput === '' || (ttl / 60).toString() === deadlineInput

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && userSlippageTolerance < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && userSlippageTolerance > 500) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }

  let deadlineError: DeadlineError | undefined
  if (deadlineInput !== '' && !deadlineInputIsValid) {
    deadlineError = DeadlineError.InvalidInput
  } else {
    deadlineError = undefined
  }

  const parseCustomSlippage = (value: string) => {
    if (value === '' || inputRegex.test(escapeRegExp(value))) {
      setSlippageInput(value)

      try {
        const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
        if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
          setUserSlippageTolerance(valueAsIntFromRoundedFloat)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const parseCustomDeadline = (value: string) => {
    setDeadlineInput(value)

    try {
      const valueAsInt: number = Number.parseInt(value) * 60
      if (!Number.isNaN(valueAsInt) && valueAsInt > 0) {
        setTtl(valueAsInt)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" alignItems="center" mb="24px" flexDirection={['column', , , , 'row']}>
        <Flex alignItems="center" mb={['12px', , , , '0']} mr="16px">
          <Text fontSize={['10px', , , , '14px']} color="black" style={{ whiteSpace: 'nowrap' }}>
            {t('Slippage Tolerance')}
          </Text>
          <QuestionHelper
            text={t(
              'Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.',
            )}
            placement="top-start"
            ml="4px"
          />
        </Flex>
        <Flex width="100%" style={{ gap: '6px' }}>
          <FlexGap justifyContent="space-between" width="100%" gap="8px" flexWrap={isMobile ? 'wrap' : 'unwrap'}>
            <Button
              scale="sm"
              onClick={() => {
                setSlippageInput('')
                setUserSlippageTolerance(10)
              }}
              variant={userSlippageTolerance === 10 ? 'slippage' : 'secondary'}
            >
              <Text fontSize="12px" color="black">
                0.1%
              </Text>
            </Button>
            <Button
              scale="sm"
              onClick={() => {
                setSlippageInput('')
                setUserSlippageTolerance(50)
              }}
              variant={userSlippageTolerance === 50 ? 'slippage' : 'secondary'}
            >
              <Text fontSize="12px" color="black">
                0.5%
              </Text>
            </Button>
            <Button
              scale="sm"
              onClick={() => {
                setSlippageInput('')
                setUserSlippageTolerance(100)
              }}
              variant={userSlippageTolerance === 100 ? 'slippage' : 'secondary'}
            >
              <Text fontSize="12px" color="black">
                1.0%
              </Text>
            </Button>
            <Flex alignItems="center" position="relative" width={['100%', , , , '105px']}>
              <Box background="#d9d9d9" padding="0 6px" borderRadius="8px" width={['100%', , , , '105px']}>
                <Input
                  scale="sm"
                  inputMode="decimal"
                  pattern="^[0-9]*[.,]?[0-9]{0,2}$"
                  placeholder={(userSlippageTolerance / 100).toFixed(2)}
                  value={slippageInput}
                  onBlur={() => {
                    parseCustomSlippage((userSlippageTolerance / 100).toFixed(2))
                  }}
                  onChange={(event) => {
                    if (event.currentTarget.validity.valid) {
                      parseCustomSlippage(event.target.value.replace(/,/g, '.'))
                    }
                  }}
                  isWarning={!slippageInputIsValid}
                  isSuccess={![10, 50, 100].includes(userSlippageTolerance)}
                  style={{ padding: '0 24px 0 5px', textAlign: 'left', fontSize: '12px', border: 'none' }}
                />
              </Box>
              <Text color="black" ml="2px" style={{ position: 'absolute', right: '5px' }}>
                %
              </Text>
            </Flex>
          </FlexGap>
        </Flex>
      </Flex>

      {!!slippageError && (
        <Text
          fontSize={['10px', , , , '14px']}
          color={slippageError === SlippageError.InvalidInput ? 'red' : 'red'}
          mb="12px"
        >
          {slippageError === SlippageError.InvalidInput
            ? t('Enter a valid slippage percentage')
            : slippageError === SlippageError.RiskyLow
            ? t('Your transaction may fail')
            : t('Your transaction may be frontrun')}
        </Text>
      )}

      <Flex justifyContent="space-between" alignItems="center" mb="24px" flexDirection={['column', , , , 'row']}>
        <Flex alignItems="center" mr="12px">
          <Text color="black" fontSize={['10px', , , , '14px']} style={{ whiteSpace: 'nowrap' }}>
            {t('Transaction deadline')}
          </Text>
          <QuestionHelper
            text={t('Your transaction will revert if it is left confirming for longer than this time.')}
            placement="top-start"
            ml="4px"
          />
        </Flex>
        <Flex width={['100%', , , , '105px']}>
          <Flex
            alignItems="center"
            background="#d9d9d9"
            width={['100%', , , , '105px']}
            mt="4px"
            borderRadius="8px"
            padding="0 6px"
          >
            <Input
              scale="sm"
              inputMode="numeric"
              pattern="^[0-9]+$"
              color={deadlineError ? 'red' : undefined}
              onBlur={() => {
                parseCustomDeadline((ttl / 60).toString())
              }}
              placeholder={(ttl / 60).toString()}
              value={deadlineInput}
              onChange={(event) => {
                if (event.currentTarget.validity.valid) {
                  parseCustomDeadline(event.target.value)
                }
              }}
              style={{ padding: '5px', textAlign: 'left', border: 'none', fontSize: '12px' }}
            />
            <Text color="black" fontSize="12px">
              Min
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SlippageTabs
